@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  vertical-align: middle;
}

.row-dragging td {
  padding: 16px;
}

.image-preview-mask .ant-image-mask-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#root {
  overflow: hidden;
}

.highlightColumn1 {
  background-color: rgba(44, 174, 53, 0.5);
}

.highlightColumn2 {
  background-color: rgba(66, 133, 244, 0.5);
}

.table-summary-center{
  text-align: center;
}

@primary-color: #2cae35;